@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

:root {
	--primary: rgb(35, 35, 35);
	--secondary: rgb(25, 110, 200);
	--white: rgb(255, 255, 255);
	--light-grey: rgb(244, 244, 244);
}

*,
*:before,
*:after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
}

body {
	font-family: 'Poppins', sans-serif;
	color: var(--primary);
}

img {
	width: 100%;
}

a {
	text-decoration: none;
}

footer {
	background-color: var(--secondary);
	color: var(--white);
	text-align: center;
	padding: 60px;
}

footer h2 {
	font-weight: 700;
	font-size: 1.5rem;
	margin: 0 0 24px;
	text-transform: uppercase;
}

@media (min-width: 768px) {
	footer h2 {
		font-size: 1.75rem;
	}
}

.email {
	color: var(--white);
}

.email:hover {
	border-bottom: 2px solid var(--white);
	transition: 0.3s ease;
}

@media screen and (min-width: 768px) {
	.project-group {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2rem;
		margin-top: 2.5rem;
	}
}
